import { centerCrop, Crop, makeAspectCrop, PixelCrop, PercentCrop } from 'react-image-crop';
import { SyntheticEvent, useCallback, useState } from 'react';
import { ImageCrop } from './ImageCrop';
import { Button, ButtonVariant } from '../../../../../Button';
import { SidebarHeader } from '../../../../../SidebarHeader';
// eslint-disable-next-line import/no-internal-modules
import 'react-image-crop/dist/ReactCrop.css';

interface Props {
  onSubmit: (value: [Crop, { width: number; height: number }]) => void;
  onClose: () => void;
  img: string;
}

const ImageEditor = ({ img, onClose, onSubmit }: Props): JSX.Element => {
  const [crop, setCrop] = useState<[Crop, { width: number; height: number }] | undefined>(undefined);

  const onCropChange = useCallback(
    (_: PixelCrop, percentCrop: PercentCrop) => setCrop((prev) => (prev ? [percentCrop, prev[1]] : prev)),
    []
  );
  const onImageLoad = useCallback((e: SyntheticEvent<HTMLImageElement>) => {
    const { height, width, naturalWidth, naturalHeight } = e.currentTarget;

    setCrop([
      centerCrop(
        makeAspectCrop(
          {
            unit: '%',
            width: 90,
            height: 90,
          },
          1,
          width,
          height
        ),
        width,
        height
      ),
      { width: naturalWidth, height: naturalHeight },
    ]);
  }, []);

  return (
    <div className="flex w-full grow flex-col bg-cream px-8 py-5 md:w-[33.875rem]">
      <SidebarHeader className="mb-4 md:mb-20" title="Юзерпик" onClose={onClose} />
      <ImageCrop img={img} crop={crop?.[0]} onCropChange={onCropChange} onImageLoad={onImageLoad} />
      {img && (
        <Button
          className="relative -top-5.5 mx-auto my-0 w-[90%] md:static md:mt-16  "
          disabled={!crop || !crop[0].width || !crop[0].height}
          variant={ButtonVariant.Contained}
          onClick={() => (crop ? onSubmit(crop) : undefined)}
        >
          Готово
        </Button>
      )}
    </div>
  );
};

export { ImageEditor };
