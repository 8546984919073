import { getDefined } from '@flame-frontend-utils/commons';
import { createContext, useContext, ReactNode } from 'react';

const SettingsContext = createContext<(() => void) | undefined>(undefined);

interface CloseSettingsProviderProps {
  onClose: () => void;
  children: ReactNode;
}

const CloseSettingsProvider = ({ children, onClose }: CloseSettingsProviderProps) => (
  <SettingsContext.Provider value={onClose}>{children}</SettingsContext.Provider>
);

const useCloseSettings = () => getDefined(useContext(SettingsContext));

export { CloseSettingsProvider, useCloseSettings };
