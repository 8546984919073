import { ChangeEvent, useCallback, useRef } from 'react';
import { CropSettings } from '@flame-frontend-utils/tiptap';
import { Button } from '../../../../Button';
import { useUploadImage } from '../../../../../react-hooks/useUploadImage';
import { MIN_CROP_SIZE } from './config';
import { Avatar } from '../../../../Avatar';

interface Props {
  currentImage: { originalUrl: string; cropSettings?: CropSettings } | undefined;
  onDone: (img: string) => void;
  text: string;
}

const ImageUpload = ({ text, currentImage, onDone }: Props): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [upload, { loading }] = useUploadImage();

  const onSelectFile = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];

      if (file) {
        const uploadedImage = await upload(file, {
          defaultPreviews: [MIN_CROP_SIZE],
        });
        onDone(uploadedImage.getPreviewUrl('original'));
      }
    },
    [onDone, upload]
  );

  const handleClick = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <label className="mt-3 inline-flex min-h-[2.5rem] max-w-full items-center" htmlFor="upload">
      {loading ? (
        <p>Загружаем...</p>
      ) : (
        <>
          <Avatar className="ml-0 mr-3 shrink-0" name="" image={currentImage} size={200} />
          <Button onClick={handleClick} disabled={loading} className="text-left">
            {text}
          </Button>
          <input
            className="h-0 w-0 opacity-0"
            tabIndex={-1}
            ref={inputRef}
            id="upload"
            type="file"
            onChange={onSelectFile}
            value=""
            accept=".jpg,.jpeg,.png,.gif"
          />
        </>
      )}
    </label>
  );
};

export { ImageUpload };
