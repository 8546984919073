import { FormProvider, useForm } from 'react-hook-form';
import { ReactNode } from 'react';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';

interface ContactsFormProps {
  children?: ReactNode;
}

interface ContactsFormState {
  tg: string;
  vk: string;
  fb: string;
}

const ContactsFormProvider = ({ children }: ContactsFormProps) => {
  const { user } = useLoginInfo();
  assertIsDefined(user);
  const {
    contacts: { tg, vk, fb },
  } = user;

  const methods = useForm<ContactsFormState>({
    defaultValues: {
      tg: tg || '',
      vk: vk || '',
      fb: fb || '',
    },
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
};

export { ContactsFormProvider };
export type { ContactsFormState };
