import { ChangeEventHandler, RefObject } from 'react';
import { Control, Controller } from 'react-hook-form';
import { DebouncedFunc } from 'lodash-es';
import { TextInput } from '../TextInput';
import { UserIdentityInputState } from './types';
import { tw } from '../../styles/tw';

interface UserIdentityInputProps<T extends UserIdentityInputState> {
  blocked: boolean;
  nicknameStatus: string;
  aliasStatus: string;
  innerRef?: RefObject<HTMLInputElement>;
  control: T extends UserIdentityInputState ? Control<T> : never;
  onNicknameChange: DebouncedFunc<ChangeEventHandler<HTMLInputElement>>;
  onAliasChange: DebouncedFunc<ChangeEventHandler<HTMLInputElement>>;
}

const UserIdentityInput = <T extends UserIdentityInputState>({
  blocked,
  control,
  nicknameStatus,
  aliasStatus,
  onNicknameChange,
  onAliasChange,
  innerRef,
}: UserIdentityInputProps<T>): JSX.Element => (
  <>
    <Controller
      control={control}
      name="nickname"
      render={({ field: { onChange, ...rest } }) => (
        <TextInput
          disabled={blocked}
          onChange={(e) => {
            onChange(e);
            onNicknameChange(e);
          }}
          {...rest}
          ref={innerRef}
          placeholder="Имя"
        />
      )}
    />
    {nicknameStatus && <div className="mb-8 mt-3">{nicknameStatus}</div>}
    <Controller
      control={control}
      name="alias"
      render={({ field: { onChange, ...rest } }) => (
        <div className="flex flex-row items-center">
          <span
            className={tw(
              'border-b border-blood px-0 py-3',
              blocked ? 'border-t-0 opacity-50' : 'border-t opacity-100'
            )}
          >
            spletnik.ru/user/
          </span>
          <TextInput
            className={tw('border-blood', blocked && 'border-t-0')}
            disabled={blocked}
            onChange={(e) => {
              onChange(e);
              onAliasChange(e);
            }}
            {...rest}
            placeholder="Ссылка"
          />
        </div>
      )}
    />
    {aliasStatus && <div className="mb-8 mt-3">{aliasStatus}</div>}
  </>
);

export { UserIdentityInput };
