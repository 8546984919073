import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CropSettings } from '@flame-frontend-utils/tiptap';
import { UpdateUserDocument } from '../UpdateUser.document';
import { UserUpdateFormState } from './types';
import { User } from '../../../../react-hooks/useLoginInfo';
import { ROUTES } from '../../../../lib/ROUTES';
import { useCloseSettings } from '../../CloseSetingsProvider';
import { ignoreReject } from '../../../../lib/ignoreReject';

export const usePersonalForm = (user: NonNullable<User>) => {
  const onClose = useCloseSettings();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { dirtyFields, isDirty, isValid },
  } = useForm<UserUpdateFormState>({
    defaultValues: {
      nickname: user.nickname || '',
      alias: user.alias || '',
      avatar: user.avatar
        ? {
            originalUrl: user.avatar.originalUrl,
            cropSettings: user.avatar.cropSettings as CropSettings | undefined,
          }
        : undefined,
    },
    mode: 'onChange',
  });

  const [mutate, { loading }] = useMutation(UpdateUserDocument);

  const onSubmit = handleSubmit(async (formData: UserUpdateFormState) => {
    const result = await ignoreReject(
      mutate({
        variables: {
          id: user.id,
          dto: {
            nickname: dirtyFields.nickname ? formData.nickname : undefined,
            alias: dirtyFields.alias ? formData.alias : undefined,
            avatar: dirtyFields.avatar ? formData.avatar : undefined,
          },
        },
      })
    );

    if (result) {
      onClose();
      if (result.data?.updateUser.alias) {
        navigate(
          ROUTES.USER_FILTER.buildPath({
            id: result.data.updateUser.alias,
          })
        );
      }
    }
  });

  return {
    control,
    onSubmit,
    loading,
    setValue,
    disabled: !isValid || !isDirty || loading,
  };
};
