import { ForwardedRef, useRef, useImperativeHandle } from 'react';

export interface Focusable {
  focus(): void;
}

export function useFocusableRef(ref: ForwardedRef<Focusable>) {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus: () => inputRef.current?.focus(),
  }));

  return inputRef;
}
