import { SocialItem } from './SocialItem';
import { ThirdPartyApp } from '../../../../lib/graphql.document';

const Social = (): JSX.Element => (
  <div className="mt-10 flex flex-col gap-6.5">
    <SocialItem networkType={ThirdPartyApp.Facebook} />
    <SocialItem networkType={ThirdPartyApp.Google} />
    <SocialItem networkType={ThirdPartyApp.Vk} />
    <SocialItem networkType={ThirdPartyApp.Ok} />
  </div>
);

export { Social };
