import { Email, Password, Personal, Social } from './components';
import { SidebarHeader } from '../SidebarHeader';
import { AccordionMenu, AccordionMenuItem } from '../AccordionMenu';
import { Contacts } from './components/Contacts';
import { CloseSettingsProvider } from './CloseSetingsProvider';

interface Props {
  initialActiveKey?: SettingsMenuItemKey;
  onClose: () => void;
}

type SettingsMenuItemKey = 'password' | 'email' | 'social' | 'personal' | 'contacts';

const MENU_ITEMS: AccordionMenuItem[] = [
  { key: 'password', title: 'Сменить пароль', content: (focusableRef) => <Password ref={focusableRef} /> },
  { key: 'email', title: 'Сменить e-mail', content: (focusableRef) => <Email ref={focusableRef} /> },
  { key: 'social', title: 'Привязать соцсети', content: <Social /> },
  { key: 'personal', title: 'Личные данные', content: (focusableRef) => <Personal ref={focusableRef} /> },
  { key: 'contacts', title: 'Контакты', content: <Contacts /> },
];

const Settings = ({ initialActiveKey, onClose }: Props): JSX.Element => (
  <CloseSettingsProvider onClose={onClose}>
    <div>
      <SidebarHeader title="Настройки" onClose={onClose} />
      <AccordionMenu menuItems={MENU_ITEMS} initialActiveKey={initialActiveKey} />
    </div>
  </CloseSettingsProvider>
);

export default Settings;
export type { SettingsMenuItemKey, Props };
