/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CurrentUserFragmentFragmentDoc } from '../../../../gql/CurrentUserFragment.document';
export type ConfirmUserEmailChangeMutationVariables = Types.Exact<{
  code: Types.Scalars['String'];
}>;


export type ConfirmUserEmailChangeMutation = { __typename?: 'Mutation', confirmUserEmailChange: { __typename?: 'User', id: string, alias: string, nickname?: string | null, nicknameChangeBlockedUntil?: number | string | null, aliasChangeBlockedUntil?: number | string | null, email: string, description?: string | null, moderationRequired: boolean, permissions: Array<Types.UserPermission>, thirdPartyApps: Array<Types.ThirdPartyApp>, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null, contacts: { __typename?: 'Contacts', vk?: string | null, tg?: string | null, fb?: string | null } } };


export const ConfirmUserEmailChangeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"ConfirmUserEmailChange"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"code"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"confirmUserEmailChange"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"code"},"value":{"kind":"Variable","name":{"kind":"Name","value":"code"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserFragment"}}]}}]}},...CurrentUserFragmentFragmentDoc.definitions]} as unknown as DocumentNode<ConfirmUserEmailChangeMutation, ConfirmUserEmailChangeMutationVariables>;