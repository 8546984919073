import { Controller } from 'react-hook-form';
import { Button, ButtonVariant } from '../../../../Button';
import { TextInput } from '../../../../TextInput';
import { useConfirmation } from './useConfirmation';
import { Errors } from '../../../../Errors';

const ConfirmationForm = () => {
  const { submitButtonDisabled, errors, onSubmit, control, loading } = useConfirmation();

  return (
    <form className="pt-10" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="code"
        render={({ field }) => <TextInput {...field} placeholder="Введите код из почты" autoComplete="one-time-code" />}
      />
      <Errors errors={errors} />
      <Button
        className="mt-14 flex w-64 justify-start"
        type="submit"
        variant={ButtonVariant.Contained}
        disabled={submitButtonDisabled}
      >
        {loading ? 'Отправляем...' : 'Готово'}
      </Button>
    </form>
  );
};

export { ConfirmationForm };
