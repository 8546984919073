import * as yup from 'yup';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { RequestUserEmailChangeDocument } from './RequestUserEmailChange.document';
import { ignoreReject } from '../../../../lib/ignoreReject';

interface EmailUpdateFormState {
  email: string;
}

const schema = yup
  .object({
    email: yup.string().email('Неверный формат email').required('Необходимо ввести email'),
  })
  .required();

export const useEmail = (onDone: () => void) => {
  const { user } = useLoginInfo();
  const [mutate, { loading }] = useMutation(RequestUserEmailChangeDocument);

  assertIsDefined(user);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<EmailUpdateFormState>({
    defaultValues: {
      email: user.email || '',
    },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const onSubmit = handleSubmit((formData: EmailUpdateFormState) => {
    void ignoreReject(
      mutate({
        variables: {
          newEmail: formData.email,
        },
      }).then(() => {
        onDone();
      })
    );
  });

  return { control, onSubmit, errors, disabled: !isValid || !isDirty, loading };
};
