import { FieldErrors } from 'react-hook-form';
import { getErrorMessages, ErrorMessage } from './getErrorMessages';

interface ErrorsProps {
  errors: FieldErrors;
  maxErrors?: number;
  reservedLines?: number;
}

const Errors = ({ errors, maxErrors, reservedLines = 0 }: ErrorsProps) => {
  const errorMessages = getErrorMessages(errors);

  const displayedMessages = [...errorMessages, ...getDummyErrorMessages(reservedLines - errorMessages.length)];

  return displayedMessages.length > 0 ? (
    <div className="mt-3">
      {displayedMessages.slice(0, maxErrors ?? displayedMessages.length).map((message) => (
        <p key={message.key}>{message.value}</p>
      ))}
    </div>
  ) : null;
};

function getDummyErrorMessages(length: number): ErrorMessage[] {
  const result = Array.from({ length });

  return result.map((_, index) => ({ key: String(index), value: '​' }));
}

export { Errors };
export type { ErrorsProps };
