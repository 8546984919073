import { useLazyQuery, useMutation } from '@apollo/client';
import { debounce, DebouncedFunc } from 'lodash-es';
import { ChangeEventHandler, useCallback, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { IsNicknameTakenDocument } from './gql/IsNicknameTaken.document';
import { IsUserAliasTakenDocument } from './gql/IsUserAliasTaken.document';
import { SuggestAliasDocument } from './gql/SuggestAlias.document';
import { getAliasStatus, getNickStatus } from './utils';
import { UserIdentityInputState } from './types';
import { ignoreReject } from '../../lib/ignoreReject';
import { User } from '../../react-hooks/useLoginInfo';

const VALUE_CHANGE_THROTTLE_WAIT = 300; // ms

interface UseAliasNickParams<T extends UserIdentityInputState> {
  control: T extends UserIdentityInputState ? Control<T> : never;
  setValue: T extends UserIdentityInputState ? UseFormSetValue<T> : never;
  blocked?: boolean;
  user?: User;
}

interface UseAliasNickResponse {
  available: boolean | undefined;
  nicknameStatus: string;
  aliasStatus: string;
  onNicknameChange: DebouncedFunc<ChangeEventHandler<HTMLInputElement>>;
  onAliasChange: DebouncedFunc<ChangeEventHandler<HTMLInputElement>>;
}

export const useUserIdentityInput = <T extends UserIdentityInputState>({
  control,
  setValue,
  blocked = false,
  user,
}: UseAliasNickParams<T>): UseAliasNickResponse => {
  const currentNickname = useWatch({ control, name: 'nickname' });
  const currentAlias = useWatch({ control, name: 'alias' });

  const [checkNicknameAvailability, nicknameAvailability] = useLazyQuery(IsNicknameTakenDocument);
  const [checkAliasAvailability, aliasAvailability] = useLazyQuery(IsUserAliasTakenDocument);
  const [suggestAlias] = useMutation(SuggestAliasDocument);

  const onNicknameChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    async (e) => {
      const newValue = e.target.value;

      if (newValue && newValue !== user?.nickname) {
        const availabilityResult = await checkNicknameAvailability({
          variables: { value: newValue },
        });
        if (availabilityResult.data && !availabilityResult.data.isTaken) {
          const aliasResponse = await ignoreReject(suggestAlias({ variables: { nickname: newValue } }));

          if (aliasResponse) {
            setValue('alias', aliasResponse.data?.suggestUserAlias || '', { shouldDirty: true });
          }
        }
      } else {
        setValue('alias', user?.alias || '', { shouldDirty: true });
      }
    },
    [checkNicknameAvailability, setValue, suggestAlias, user?.alias, user?.nickname]
  );

  const onAliasChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const alias = e.target.value;
      if (alias && alias !== user?.alias) {
        void checkAliasAvailability({ variables: { value: alias } });
      }
    },
    [checkAliasAvailability, user?.alias]
  );

  const onNicknameChangeWithThrottle = useMemo(
    () => debounce(onNicknameChange, VALUE_CHANGE_THROTTLE_WAIT),
    [onNicknameChange]
  );

  const onAliasChangeWithThrottle = useMemo(() => debounce(onAliasChange, VALUE_CHANGE_THROTTLE_WAIT), [onAliasChange]);

  return {
    available:
      nicknameAvailability.data &&
      !nicknameAvailability.data.isTaken &&
      aliasAvailability.data &&
      !aliasAvailability.data.isTaken,
    onNicknameChange: onNicknameChangeWithThrottle,
    onAliasChange: onAliasChangeWithThrottle,
    nicknameStatus: blocked ? '' : getNickStatus(nicknameAvailability, currentNickname, user?.nickname),
    aliasStatus: blocked ? '' : getAliasStatus(aliasAvailability, currentAlias, user?.alias),
  };
};
