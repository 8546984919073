import { ContactsFormProvider } from './ContactsFormProvider';
import { ContactsForm } from './ContactsForm';

const Contacts = () => (
  <ContactsFormProvider>
    <ContactsForm />
  </ContactsFormProvider>
);

export { Contacts };
