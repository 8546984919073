import { ThirdPartyApp } from './graphql.document';

const GOOGLE_AUTH_ENDPOINT = '/auth/google';
const VK_AUTH_ENDPOINT = '/auth/vk';
const FACEBOOK_AUTH_ENDPOINT = '/auth/facebook';
const OK_AUTH_ENDPOINT = '/auth/ok';

type SocialNetwork = Exclude<ThirdPartyApp, ThirdPartyApp.Twitter>;
type AuthItem = {
  title: string;
  to: string;
};

const GOOGLE_AUTH: AuthItem = {
  title: 'Google',
  to: `${PUBLIC_CONFIG.BASE_API_URL}${GOOGLE_AUTH_ENDPOINT}`,
};

const FACEBOOK_AUTH: AuthItem = {
  title: 'Facebook',
  to: `${PUBLIC_CONFIG.BASE_API_URL}${FACEBOOK_AUTH_ENDPOINT}`,
};

const VK_AUTH: AuthItem = {
  title: 'VK',
  to: `${PUBLIC_CONFIG.BASE_API_URL}${VK_AUTH_ENDPOINT}`,
};

const OK_AUTH: AuthItem = {
  title: 'OK',
  to: `${PUBLIC_CONFIG.BASE_API_URL}${OK_AUTH_ENDPOINT}`,
};

const SocialNetworks: Record<SocialNetwork, { title: string; to: string }> = {
  [ThirdPartyApp.Google]: GOOGLE_AUTH,
  [ThirdPartyApp.Facebook]: FACEBOOK_AUTH,
  [ThirdPartyApp.Vk]: VK_AUTH,
  [ThirdPartyApp.Ok]: OK_AUTH,
};

export { GOOGLE_AUTH, VK_AUTH, FACEBOOK_AUTH, OK_AUTH, SocialNetworks };
export type { SocialNetwork };
