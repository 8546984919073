import { useOpenState } from '@flame-frontend-utils/commons';
import { useCallback, useState } from 'react';
import { Crop } from 'react-image-crop';
import { CropSettings } from '@flame-frontend-utils/tiptap';
import { Sidebar } from '../../../../Sidebar';
import { ImageEditor } from './ImageEditor';
import { ImageUpload } from './ImageUpload';

interface ImageProcessorProps {
  value: { originalUrl: string; cropSettings?: CropSettings } | undefined;
  onChange: (val: { originalUrl: string; cropSettings?: CropSettings }) => void;
}

export const ImageProcessor = ({ value, onChange }: ImageProcessorProps) => {
  const [isOpen, onOpen, onClose] = useOpenState(false);
  const [img, setImg] = useState('');

  const onImageSelect = useCallback(
    (src: string) => {
      setImg(src);
      onOpen();
    },
    [onOpen]
  );

  const onSubmit = useCallback(
    (crop: [Crop, { width: number; height: number }]) => {
      if (crop[0].unit === '%') {
        const cropSettings: CropSettings = {
          x: (crop[0].x / 100) * crop[1].width,
          y: (crop[0].y / 100) * crop[1].height,
          width: (crop[0].width / 100) * crop[1].width,
          height: (crop[0].width / 100) * crop[1].width, // Guarantee square image
          scale: 1,
          originalWidth: crop[1].width,
          originalHeight: crop[1].height,
        };

        onChange({ originalUrl: img, cropSettings });
        onClose();
      } else {
        throw new Error('Expected percentage crop.');
      }
    },
    [img, onChange, onClose]
  );

  return (
    <>
      <ImageUpload onDone={onImageSelect} currentImage={value} text="Заменить юзерпик, png или jpg" />
      <Sidebar isOpen={isOpen} onRequestClose={onClose}>
        <ImageEditor onSubmit={onSubmit} onClose={onClose} img={img} />
      </Sidebar>
    </>
  );
};
