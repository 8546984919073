import { SVGAttributes } from 'react';
import { tw } from '../../../styles/tw';
import Cross from './cross.svg?svgr';

type CrossIconProps = SVGAttributes<SVGElement> & {
  isOpen: boolean;
};

const CrossIcon = ({ isOpen, className, ...restProps }: CrossIconProps) => (
  <Cross
    className={tw('transition-transform duration-100', isOpen ? 'rotate-45' : 'rotate-0', className)}
    {...restProps}
  />
);

export { CrossIcon };
