import { useState } from 'react';
import { AccordionMenuItem } from './types';
import { AccordionMenuItemDisplay } from './AccordionMenuItemDisplay';

interface AccordionMenuProps {
  menuItems: AccordionMenuItem[];
  initialActiveKey?: string;
}

const AccordionMenu = ({ menuItems, initialActiveKey }: AccordionMenuProps): JSX.Element => {
  const [activeKey, setActiveKey] = useState<string | null>(initialActiveKey ?? null);

  const onAccordionOpen = (key: string) => {
    setActiveKey(key);
  };

  const onAccordionClose = () => {
    setActiveKey(null);
  };

  return (
    <div className="flex flex-col [&>:last-child]:border-b [&>div]:border-t [&>div]:border-blood">
      {menuItems.map(({ key, title, content }) => (
        <AccordionMenuItemDisplay
          key={key}
          id={key}
          title={title}
          isOpen={key === activeKey}
          onOpen={onAccordionOpen}
          onClose={onAccordionClose}
        >
          {content}
        </AccordionMenuItemDisplay>
      ))}
    </div>
  );
};

export { AccordionMenu };
