import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';
import { noun } from 'plural-ru';

function getRemainingTimeString(releaseDate: string | number | null | undefined) {
  if (!releaseDate) {
    return null;
  }
  const remainingTime = getRemainingTime(new Date(releaseDate));

  return remainingTime ? `Внимание! Имя и ссылку можно в следующий раз поменять через ${remainingTime}` : null;
}

function getRemainingTime(releaseDate: Date) {
  const now = Date.now();
  const days = differenceInDays(releaseDate, now);
  const hours = differenceInHours(releaseDate, now);
  const minutes = differenceInMinutes(releaseDate, now);

  if (days > 0) {
    return `${days} ${noun(days, 'день', 'дня', 'дней')}`;
  }

  if (hours > 0) {
    return `${hours} ${noun(hours, 'час', 'часа', 'часов')}`;
  }

  if (minutes > 0) {
    return `${minutes} ${noun(minutes, 'минуту', 'минуты', 'минут')}`;
  }

  return null;
}

export { getRemainingTimeString };
