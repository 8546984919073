import { Controller, useFormContext } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { ContactInput } from './ContactInput';
import { Button, ButtonVariant } from '../../../Button';
import { ContactsFormState } from './ContactsFormProvider';
import { UpdateUserDocument } from '../UpdateUser.document';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { useCloseSettings } from '../../CloseSetingsProvider';
import { ignoreReject } from '../../../../lib/ignoreReject';

const ContactsForm = () => {
  const { user } = useLoginInfo();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useFormContext<ContactsFormState>();
  const [mutate, { loading }] = useMutation(UpdateUserDocument);
  const onClose = useCloseSettings();
  assertIsDefined(user);

  const onSubmit = handleSubmit((data) => {
    void ignoreReject(
      mutate({
        variables: {
          id: user.id,
          dto: { contacts: data },
        },
      }).then(() => {
        onClose();
      })
    );
  });

  return (
    <form className="mt-10 flex flex-col border-t border-blood" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="tg"
        render={({ field }) => <ContactInput {...field} buttonText="Telegram" prefix="https://t.me/" />}
      />
      <Controller
        control={control}
        name="vk"
        render={({ field }) => <ContactInput {...field} buttonText="VK" prefix="https://vk.com/" />}
      />
      <Controller
        control={control}
        name="fb"
        render={({ field }) => <ContactInput {...field} buttonText="Facebook" prefix="https://fb.com/" />}
      />
      <Button className="mt-14" type="submit" variant={ButtonVariant.Contained} disabled={loading || !isDirty}>
        {loading ? 'Сохраняем...' : 'Готово'}
      </Button>
    </form>
  );
};

export { ContactsForm };
