import { Controller } from 'react-hook-form';
import { useState, forwardRef, ForwardedRef } from 'react';
import { TextInput } from '../../../TextInput';
import { Button, ButtonVariant } from '../../../Button';
import { useEmail } from './useEmail';
import { ConfirmationForm } from './ConfirmationForm';
import { Errors } from '../../../Errors';
import { Focusable, useFocusableRef } from '../../../../react-hooks/useFocusableRef';

const Email = forwardRef((_, ref: ForwardedRef<Focusable>): JSX.Element => {
  const [isChangeRequested, setIsChangeRequested] = useState(false);
  const { control, disabled, loading, errors, onSubmit } = useEmail(() => setIsChangeRequested(true));

  const inputRef = useFocusableRef(ref);

  return !isChangeRequested ? (
    <form className="pt-10" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <TextInput {...field} ref={inputRef} placeholder="Новый e-mail" type="email" autoComplete="email" />
        )}
      />
      <Errors maxErrors={1} reservedLines={1} errors={errors} />
      <Button className="mt-5 w-full" disabled={disabled} variant={ButtonVariant.Contained} type="submit">
        {loading ? 'Меняем...' : 'Сменить e-mail'}
      </Button>
    </form>
  ) : (
    <ConfirmationForm />
  );
});

export { Email };
