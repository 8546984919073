import { Controller } from 'react-hook-form';
import { ForwardedRef, forwardRef } from 'react';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { usePersonalForm } from './usePersonalForm';
import { Button, ButtonVariant } from '../../../Button';
import { ImageProcessor } from './ImageProcessor';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { getRemainingTimeString } from './utils';
import { Focusable, useFocusableRef } from '../../../../react-hooks/useFocusableRef';
import { UserIdentityInput, useUserIdentityInput } from '../../../UserIdentityInput';

const Personal = forwardRef((_, ref: ForwardedRef<Focusable>): JSX.Element => {
  const { user } = useLoginInfo();
  assertIsDefined(user);
  const { nicknameChangeBlockedUntil, aliasChangeBlockedUntil } = user;

  const blockedUntil =
    nicknameChangeBlockedUntil || aliasChangeBlockedUntil
      ? Math.max(new Date(nicknameChangeBlockedUntil ?? 0).valueOf(), new Date(aliasChangeBlockedUntil ?? 0).valueOf())
      : undefined;

  const blocked = blockedUntil ? Date.now() < blockedUntil : false;

  const { setValue, control, onSubmit, loading, disabled } = usePersonalForm(user);
  const inputRef = useFocusableRef(ref);
  const remainingTime = getRemainingTimeString(blockedUntil);
  const { nicknameStatus, onNicknameChange, onAliasChange, available, aliasStatus } = useUserIdentityInput({
    control,
    setValue,
    blocked,
    user,
  });

  return (
    <form className="pt-6.5" onSubmit={onSubmit}>
      <div className="mb-12">{remainingTime || 'Внимание! Имя и ссылку можно менять раз в месяц'}</div>
      <UserIdentityInput
        innerRef={inputRef}
        nicknameStatus={nicknameStatus}
        aliasStatus={aliasStatus}
        blocked={blocked}
        control={control}
        onNicknameChange={onNicknameChange}
        onAliasChange={onAliasChange}
      />
      <Controller
        control={control}
        name="avatar"
        render={({ field: { ref: __, ...rest } }) => <ImageProcessor {...rest} />}
      />
      <Button
        className="mt-5.5 w-full"
        variant={ButtonVariant.Contained}
        disabled={disabled || (!blocked && available === false)}
        type="submit"
      >
        {loading ? 'Обновляем' : 'Готово'}
      </Button>
    </form>
  );
});

export { Personal };
