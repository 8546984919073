import { useCallback, ChangeEvent, RefCallback, forwardRef, ForwardedRef } from 'react';
import { IMaskMixin } from 'react-imask';
import { useOpenState } from '@flame-frontend-utils/commons';
import { TextInput, TextInputProps } from '../../../TextInput';
import { Button } from '../../../Button';
import { tw } from '../../../../styles/tw';

interface ContactInputProps extends Omit<TextInputProps, 'onChange'> {
  buttonText: string;
  prefix: string;
  onChange(value: string): void;
}

const ContactInput = forwardRef(
  (
    { value, prefix, buttonText, disabled, onChange, ...rest }: ContactInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const [isOpen, onOpen] = useOpenState();

    const inputValue = typeof value === 'string' && value.startsWith(prefix) ? value.replace(prefix, '') : '';

    const onInnerChange = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value ? prefix + event.target.value : '');
      },
      [onChange, prefix]
    );

    const onSetRef = (element: HTMLInputElement) => {
      if (typeof ref === 'function') {
        ref(element);
      } else if (ref) {
        // eslint-disable-next-line no-param-reassign
        ref.current = element;
      }
    };

    return isOpen ? (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className="flex flex-row items-center">
        <span className={tw('border-b border-blood px-0 py-3 text-half-blood', disabled && 'opacity-50')}>
          {prefix}
        </span>
        <MaskedStyledInput
          {...rest}
          inputRef={onSetRef}
          disabled={disabled}
          value={inputValue}
          onChange={onInnerChange}
          /* Underscores for TG and VK, dots for FB */
          mask={/^[a-z0-9_.]+$/i}
        />
      </label>
    ) : (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className="cursor-pointer border-b border-blood px-0 py-3">
        <Button onClick={onOpen}>{buttonText}</Button>
      </label>
    );
  }
);

const MaskedStyledInput = IMaskMixin(
  ({ inputRef, className, ...props }: { inputRef: RefCallback<HTMLInputElement> } & TextInputProps) => (
    <TextInput className={tw('border-t-0', className)} {...props} ref={inputRef} autoFocusOnMount />
  )
);

export { ContactInput };
