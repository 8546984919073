import { ReactNode, useState, TransitionEvent, useLayoutEffect, useRef } from 'react';
import { Button } from '../../Button';
import { CrossIcon } from './CrossIcon';
import { tw } from '../../../styles/tw';

declare module 'react' {
  interface CSSProperties {
    '--content-height'?: string;
  }
}

interface Props {
  summary: string;
  children: ReactNode;
  isOpen: boolean;
  className?: string;
  onClose: () => void;
  onOpen: () => void;
  onTransitionEnd?: () => void;
}

const Accordion = ({ summary, children, isOpen, className, onOpen, onClose, onTransitionEnd }: Props): JSX.Element => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useLayoutEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, []);

  const onInnerTransitionEnd = (event: TransitionEvent) => {
    if (event.currentTarget === event.target) {
      onTransitionEnd?.();
    }
  };

  return (
    <div className={className}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="flex w-full cursor-pointer flex-row justify-between px-0 py-3 hover:bg-blood hover:text-cream [&>*]:transition-none [@media(hover:hover){&:hover_svg}]:text-cream"
        onClick={!isOpen ? onOpen : onClose}
      >
        <div>{summary}</div>
        <Button>
          <CrossIcon isOpen={isOpen} />
        </Button>
      </div>
      <div
        style={{ '--content-height': `${contentHeight / 16}rem` }}
        className={tw(
          'overflow-hidden transition-[max-height,grow] duration-300 ease-in-out',
          isOpen ? 'max-h-[var(--content-height)]' : 'max-h-0'
        )}
        ref={contentRef}
        onTransitionEnd={onInnerTransitionEnd}
      >
        {children}
        <div className="h-10" />
      </div>
    </div>
  );
};

export { Accordion };
