import { QueryResult } from '@apollo/client';
import { Exact } from '../../../lib/graphql.document';
import { IsNicknameTakenQuery } from '../gql/IsNicknameTaken.document';
import { IsUserAliasTakenQuery } from '../gql/IsUserAliasTaken.document';

function getNickStatus(
  queryResult: QueryResult<IsNicknameTakenQuery, Exact<{ value: string }>>,
  nickname: string,
  prevNickname: string | null | undefined
) {
  if (nickname) {
    if (nickname === prevNickname) {
      return 'Это ваше текущее имя';
    }
    if (queryResult.loading) {
      return 'Проверяем...';
    }

    if (queryResult.data?.isTaken) {
      return 'Такое имя уже существует';
    }

    if (queryResult.data && !queryResult.data.isTaken) {
      return 'Имя свободно';
    }
  }

  return 'Введите имя';
}

function getAliasStatus(
  queryResult: QueryResult<IsUserAliasTakenQuery, Exact<{ value: string }>>,
  alias: string,
  prevAlias: string | null | undefined
) {
  if (alias) {
    if (alias === prevAlias) {
      return 'Это ваша текущая ссылка';
    }

    if (queryResult.loading) {
      return 'Проверяем...';
    }

    if (queryResult.data?.isTaken) {
      return 'Такая ссылка уже существует';
    }

    if (queryResult.data && !queryResult.data.isTaken) {
      return 'Ссылка свободна';
    }
  }

  return 'Введите ссылку';
}

export { getNickStatus, getAliasStatus };
