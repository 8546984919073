/**
 * NOTE: THIS IS AN AUTO-GENERATED FILE. DO NOT MODIFY IT DIRECTLY.
 */

/* eslint-disable */
// @ts-nocheck

import * as Types from '../../../lib/graphql.document';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
import { CurrentUserFragmentFragmentDoc } from '../../../gql/CurrentUserFragment.document';
export type UpdateUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  dto: Types.UserUpdateParams;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, alias: string, nickname?: string | null, nicknameChangeBlockedUntil?: number | string | null, aliasChangeBlockedUntil?: number | string | null, email: string, description?: string | null, moderationRequired: boolean, permissions: Array<Types.UserPermission>, thirdPartyApps: Array<Types.ThirdPartyApp>, avatar?: { __typename?: 'Avatar', originalUrl: string, cropSettings?: unknown | null } | null, contacts: { __typename?: 'Contacts', vk?: string | null, tg?: string | null, fb?: string | null } } };


export const UpdateUserDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateUser"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"id"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"dto"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserUpdateParams"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateUser"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"id"}}},{"kind":"Argument","name":{"kind":"Name","value":"params"},"value":{"kind":"Variable","name":{"kind":"Name","value":"dto"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"CurrentUserFragment"}}]}}]}},...CurrentUserFragmentFragmentDoc.definitions]} as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;