import { SyntheticEvent } from 'react';
import { Component as ReactCrop, Crop, PercentCrop, PixelCrop } from 'react-image-crop';
import { MIN_CROP_SIZE } from '../config';
import { tw } from '../../../../../../styles/tw';

interface Props {
  img: string;
  crop: Crop | undefined;
  onCropChange: (pixelCrop: PixelCrop, percentCrop: PercentCrop) => void;
  onImageLoad: (e: SyntheticEvent<HTMLImageElement>) => void;
}

const ImageCrop = ({ img, crop, onImageLoad, onCropChange }: Props): JSX.Element | null =>
  img ? (
    <ReactCrop
      className={cropStyles}
      minWidth={MIN_CROP_SIZE}
      minHeight={MIN_CROP_SIZE}
      circularCrop
      aspect={1}
      crop={crop}
      onChange={onCropChange}
      keepSelection
    >
      <img
        className="pointer-events-none select-none"
        width="100%"
        src={img}
        alt="Исходный файл"
        onLoad={onImageLoad}
      />
    </ReactCrop>
  ) : null;

/** @see https://github.com/tailwindlabs/tailwindcss/issues/8881#issuecomment-1208600668 */
const cropStyles = tw(
  String.raw`[&&_.ReactCrop\_\_crop-selection]:border-none [&&_.ReactCrop\_\_crop-selection]:shadow-overlay [&_.ReactCrop\_\_drag-handle:after]:h-8 [&_.ReactCrop\_\_child-wrapper]:cursor-auto [&_.ReactCrop\_\_drag-handle:after]:bg-transparent [&_.ReactCrop\_\_drag-handle:after]:w-8 [&_.ReactCrop\_\_drag-handle:after]:border-0 [&_.ReactCrop\_\_drag-handle:after]:bg-none [&_.ord-ne:after]:border-r [&_.ord-ne:after]:border-t [&_.ord-ne:after]:border-blood [&_.ord-nw:after]:border-l [&_.ord-nw:after]:border-t [&_.ord-nw:after]:border-blood [&_.ord-se:after]:border-b [&_.ord-se:after]:border-r [&_.ord-se:after]:border-blood [&_.ord-sw:after]:border-b [&_.ord-sw:after]:border-l [&_.ord-sw:after]:border-blood`
);

export { ImageCrop };
