import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ChangeUserPasswordDocument } from './ChangeUserPassword.document';
import { ChangePasswordState } from './types';
import { useCloseSettings } from '../../CloseSetingsProvider';
import { ignoreReject } from '../../../../lib/ignoreReject';

const schema = yup
  .object({
    password: yup.string().required('Необходимо ввести пароль'),
  })
  .required();

export const useChangePassword = () => {
  const [changePassword, { loading }] = useMutation(ChangeUserPasswordDocument);
  const onClose = useCloseSettings();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ChangePasswordState>({
    defaultValues: { password: '' },
    resolver: yupResolver(schema),
    mode: 'onChange',
  });

  const submitButtonDisabled = !isValid || loading;

  const onSubmit = handleSubmit(({ password }: ChangePasswordState) => {
    void ignoreReject(
      changePassword({ variables: { password } }).then(() => {
        onClose();
      })
    );
  });

  return { control, errors, onSubmit, submitButtonDisabled, loading };
};
