import { useMutation } from '@apollo/client';
import { Button } from '../../../Button';
import { UpdateUserDocument } from '../UpdateUser.document';
import { SocialNetwork, SocialNetworks } from '../../../../lib/auth';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { ignoreReject } from '../../../../lib/ignoreReject';

interface SocialItemProps {
  networkType: SocialNetwork;
}

const SocialItem = ({ networkType }: SocialItemProps) => {
  const { user } = useLoginInfo();
  const [mutate, { loading }] = useMutation(UpdateUserDocument);

  if (!user) {
    return null;
  }

  const { id, thirdPartyApps } = user;

  const { title, to } = SocialNetworks[networkType];
  const isLinked = Boolean(thirdPartyApps.includes(networkType));

  const onClick = () => {
    void ignoreReject(mutate({ variables: { id, dto: { resetThirdPartyApps: [networkType] } } }));
  };

  return (
    <div className="flex justify-between">
      <p>{title}</p>
      {isLinked ? (
        <Button onClick={onClick} disabled={loading}>
          Отвязать
        </Button>
      ) : (
        <Button to={to} target="_self" isExternal>
          Привязать
        </Button>
      )}
    </div>
  );
};

export { SocialItem };
