import { useRef, ReactNode, RefObject } from 'react';
import { Accordion } from './Accordion';
import { Focusable } from '../../react-hooks/useFocusableRef';

interface AccordionMenuItemProps {
  id: string;
  title: string;
  isOpen: boolean;
  onOpen(id: string): void;
  onClose(): void;
  children: ReactNode | ((focusableRef: RefObject<Focusable>) => ReactNode);
}

const AccordionMenuItemDisplay = ({ id, title, isOpen, children, onOpen, onClose }: AccordionMenuItemProps) => {
  const focusableRef = useRef<Focusable>(null);

  const onInnerOpen = () => {
    onOpen(id);
  };

  const onTransitionEnd = () => {
    if (isOpen) {
      focusableRef.current?.focus();
    }
  };

  return (
    <Accordion summary={title} isOpen={isOpen} onOpen={onInnerOpen} onClose={onClose} onTransitionEnd={onTransitionEnd}>
      {typeof children === 'function' ? children(focusableRef) : children}
    </Accordion>
  );
};

export { AccordionMenuItemDisplay };
