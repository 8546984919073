import { Controller } from 'react-hook-form';
import { forwardRef, ForwardedRef } from 'react';
import { TextInput } from '../../../TextInput';
import { Button, ButtonVariant } from '../../../Button';
import { useChangePassword } from './useChangePassword';
import { Errors } from '../../../Errors';
import { Focusable, useFocusableRef } from '../../../../react-hooks/useFocusableRef';

const Password = forwardRef((_, ref: ForwardedRef<Focusable>): JSX.Element => {
  const { control, submitButtonDisabled, loading, errors, onSubmit } = useChangePassword();

  const inputRef = useFocusableRef(ref);

  return (
    <form className="pt-10" onSubmit={onSubmit}>
      <Controller
        control={control}
        name="password"
        render={({ field }) => (
          <TextInput {...field} ref={inputRef} type="password" placeholder="Новый пароль" autoComplete="new-password" />
        )}
      />
      <Errors maxErrors={1} reservedLines={1} errors={errors} />
      <Button className="mt-5.5 w-full" disabled={submitButtonDisabled} variant={ButtonVariant.Contained} type="submit">
        {loading ? 'Меняем...' : 'Сменить пароль'}
      </Button>
    </form>
  );
});

export { Password };
